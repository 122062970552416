import axios from 'axios'
import qs from 'qs'
import { filterEmptyParams } from './requestTools'
import { Toast } from 'antd-mobile'

const createHistory = require('history').createHashHistory
axios.interceptors.request.use(
  function (config) {
    const { url } = config
    const isHideLoading = url.includes('zZHideLoading=yes')

    // console.log('url', url.includes('/HomePageAuction/getBid'));
    if (!isHideLoading) {
      //HomePageAuction/getBid
      Toast.show({
        icon: 'loading',
        content: '加载中…',
        maskClickable: false,
        duration: 1000
      })
    }

    config.headers['Authorization'] = localStorage.getItem('Authorization')
    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    if (response.headers['content-type'].indexOf('image') > -1) {
      var binary = ''
      var bytes = new Uint8Array(response.data)
      var len = bytes.byteLength
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      // Toast.clear()
      return { status: 200, data: window.btoa(binary) }
    }
    if (response.data.code !== 0) {
      if (response.config.url.indexOf('currentUser') === -1) {
        Toast.show({ content: response.data.message })
      }
      if (response.data.code === 302) {
        // 用户被禁用
        alert('您的帐号已被停用')
        localStorage.clear()
        sessionStorage.clear()
        window.location.href = '/'
        return Promise.reject(response)
      }
      return Promise.reject(response)
    } else {
      Toast.clear()
    }
    return response
  },
  function (error) {
    if (error.toString().indexOf('status code 403') > 0) {
      const history = createHistory()
      const pass = window.location.hash.includes('/mall')
      if (!pass) history.push('/login')
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)
const defaultTableOption = {
  pageNo: 1,
  pageSize: 20
}

const commonRequestPath = ''
export const partSignPath = (() => {
  // let host = '172.20.16.141:8091/api'
  // return `http://${host}`
  // return `https://yanjiapeng-paimai-wechat.f.wmeimob.com/api`
  // return `http://172.18.58.14:8091/api`
  return '/api'
})()

const createRequest = (method, url, params) => {
  const queryString = qs.stringify(params, { arrayFormat: 'indices', allowDots: true })

  if (method === 'get') {
    return axios.get(`${partSignPath}${url}?${queryString}`)
  }

  if (method === 'post') {
    return axios.post(partSignPath + url, params)
  }

  if (method === 'put') {
    return axios.put(partSignPath + url, params)
  }

  if (method === 'delete') {
    return axios.delete(partSignPath + url, { data: params })
  }

  if (method === 'upload') {
    const data = new FormData()
    const keys = Object.keys(params)
    keys.forEach(key => {
      data.append(key, params[key])
    })
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    return axios.post(partSignPath + url, data, config)
  }
  if (method === 'arraybuffer-get') {
    return axios.get(`${partSignPath}${url}?${queryString}`, { responseType: 'arraybuffer' })
  }

  return Promise.reject('不被支持的请求')
}

// 过滤请求返回值
const filterResponse = response => {
  if (response.status === 200 || response.status === 206 || response.status === 304) {
    return response.data
  }
  return response.message
}

/**
 * @param {Object} options
 * @param {String} options.method 请求类型 "get" || "post"
 * @param {String} options.url 请求接口路径 "/v1/web/xxxxxx"
 * @param {Object} options.params 请求参数 { pageIndex:5, pageRows:10 }
 * @param {Boolean} options.isTableData 请求数据是否为表格数据
 * @param {Number || Boolean} options.mockDuration mock请求 请求时长模拟
 * @param {Boolean} options.keepEmpty 请求参数是否保留空数据
 */
export default function request(options) {
  const { method, url, params, isTableData, mockDuration, keepEmpty } = options

  let reqParams = filterEmptyParams(params, keepEmpty)
  // console.log('request-reqParams', reqParams)

  // 如果请求忘记，添加分页默认参数
  if (isTableData) {
    reqParams = Object.assign({}, defaultTableOption, reqParams)
  }
  let reqUrl = ''
  if (/^http|\/v1\/|\/v4\/|\/v8\//.test(url)) {
    let newURL = url.replace(/^v1/, 'v8')

    reqUrl = newURL
  } else {
    reqUrl = commonRequestPath + url
  }
  return new Promise((resolve, reject) => {
    createRequest(method, reqUrl, reqParams)
      .then(res => {
        if (!res) res = {}
        const response = filterResponse(res)
        Toast.clear()
        // const { code } = response || {};
        if (res.status === 200 || res.status === 206 || res.status === 304) {
          setTimeout(() => {
            resolve(response)
          }, mockDuration || 0)
        } else {
          reject(response)
        }
      })
      .catch(err => {
        setTimeout(() => {
          Toast.clear()
        }, 2000)
        reject(err)
      })
  })
}
