import request from '@/utils/request'

//获取验证码
export const sendSms = params =>
  request({
    method: 'get',
    url: '/mobile/sendSms',
    params,
    isTableData: false,
    mockDuration: 0
  })

export function queryMobileLogin(params) {
  return request({
    method: 'post',
    url: `/mobile/login?smsCode=${params.smsCode}&userPhone=${params.userPhone}`
  })
}

//登录
export const loginByProfile = params =>
  request({
    method: 'post',
    url: '/mobile/loginByProfile',
    params,
    isTableData: false,
    mockDuration: 0
  })
//获取用户信息
export const currentUser = params =>
  request({
    method: 'get',
    url: '/currentUser',
    params,
    isTableData: false,
    mockDuration: 0
  })

export const getAuthorizeUri = params => request({ method: 'get', url: '/mobile/wechat-mp/authorize_uri', params })

export const getAuthUserInfo = openid =>
  request({ method: 'get', url: '/mobile/wechat-mp/get-auth-userinfo', params: { openid } })

export function querySyncUserInfo(params) {
  return request({
    method: 'post',
    url: '/mobile/synchronizationInfo',
    params
  })
}

export function queryUserAgreement() {
  return request({
    method: 'get',
    url: '/UserAgreement/page'
  })
}
