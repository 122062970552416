import { Toast } from 'antd-mobile'
import { getAuthUserInfo, querySyncUserInfo } from '../request/login/request'

const Auth = ({ location, history }) => {
  const { search } = location
  console.log('auth.js', location, search)
  if (search && search.startsWith('?openid=')) {
    // 取微信用户信息
    const openId = search.substring(8)
    console.log('auth.js ==> openId', openId)
    getAuthUserInfo(openId)
      .then(r => {
        if (r.code) {
          throw new Error(r.message)
        }
        const wxUserInfo = JSON.parse(localStorage.getItem('wxUser'))
        console.log(openId)
        if (wxUserInfo?.userH5Entity?.openId && openId !== wxUserInfo?.userH5Entity?.openId) {
          localStorage.clear()
          sessionStorage.clear()
          alert('不能使用不同微信号登录')
          return
        }
        // 保存微信用户信息到本地 wxUser
        localStorage.setItem('wxUser', JSON.stringify({ ...wxUserInfo, ...r.data, openId: wxUserInfo.openid }))
        const sharedVip = localStorage.getItem('sharedVip')
        querySyncUserInfo({
          userId: wxUserInfo.dealerId,
          openId: r.data.openid,
          userName: r.data.nickname,
          gender: r.data.sex,
          headPortrait: r.data.headimgurl,
          sharedVip
        })
        localStorage.removeItem('sharedVip')
        // 处理由 router/index.js 中保存的登录跳转路径
        const toPath = localStorage.getItem('LOGIN_TO_PATH')
        if (toPath) {
          localStorage.removeItem('LOGIN_TO_PATH')
          history.replace(toPath)
        } else {
          history.replace('/')
        }
      })
      .catch(e => {
        Toast.show({ content: e.message })
        history.replace('/')
      })
  } else {
    history.replace('/')
  }

  return <span>授权处理中</span>
}

export default Auth
