import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import AsyncLoad from '@/pages/components/AsyncLoad'
import Auth from '../pages/auth'
import { getUserId } from '../utils/base'
// 基本模块
const Home = AsyncLoad(() => import('@/pages/home'))
const Guarantee = AsyncLoad(() => import('@/pages/home/guarantee'))
const Login = AsyncLoad(() => import('@/pages/login'))
const UserAgreement = AsyncLoad(() => import('@/pages/login/UserAgreement'))
const LegalPrivacy = AsyncLoad(() => import('@/pages/login/LegalPrivacy'))
const AuthIndex = AsyncLoad(() => import('@/pages/login/auth'))
const Search = AsyncLoad(() => import('@/pages/search'))
const Artist = AsyncLoad(() => import('@/pages/artist'))
const ArtistDetail = AsyncLoad(() => import('@/pages/artist/detail'))

const HotShotDetail = AsyncLoad(() => import('@/pages/hotShot/detail'))
const Auction = AsyncLoad(() => import('@/pages/auction'))
const AuctionDetail = AsyncLoad(() => import('@/pages/auction/detail'))
const AuctionSearch = AsyncLoad(() => import('@/pages/auctionSearch'))
const AuctionBidResult = AsyncLoad(() => import('@/pages/auction/result'))
const AuctionNotice = AsyncLoad(() => import('@/pages/auction/notice'))
const Oeuvre = AsyncLoad(() => import('@/pages/oeuvre'))
const OeuvreDetail = AsyncLoad(() => import('@/pages/oeuvre/detail'))
const Channel = AsyncLoad(() => import('@/pages/channel'))
const Recommend = AsyncLoad(() => import('@/pages/recommend'))
const Special = AsyncLoad(() => import('@/pages/special'))
const OrderLogistics = AsyncLoad(() => import('@/pages/order/logistics'))
const OrderLogisticsOrder = AsyncLoad(() => import('@/pages/order/logisticsOrder'))

// 新个人中心
const Center = AsyncLoad(() => import('@/pages/center'))
const CenterInfo = AsyncLoad(() => import('@/pages/center/info'))
const CenterInfoNickname = AsyncLoad(() => import('@/pages/center/info/nickname'))
const CenterMessage = AsyncLoad(() => import('@/pages/center/message'))
const CenterMessageDetail = AsyncLoad(() => import('@/pages/center/message/detail'))
const CenterCompete = AsyncLoad(() => import('@/pages/center/compete'))
const CompeteDetail = AsyncLoad(() => import('@/pages/center/compete/detail'))
const CompetePay = AsyncLoad(() => import('@/pages/center/compete/pay'))
const ArtisticAdviser = AsyncLoad(() => import('@/pages/center/adviser'))
const NewExclusive = AsyncLoad(() => import('@/pages/center/new'))
const VipEquity = AsyncLoad(() => import('@/pages/center/equity'))
const VipEquityDetail = AsyncLoad(() => import('@/pages/center/equity/detail'))
const VipExclusive = AsyncLoad(() => import('@/pages/center/vip'))
const CenterService = AsyncLoad(() => import('@/pages/center/service'))
const CenterAddress = AsyncLoad(() => import('@/pages/center/address'))
const CenterAddressDetail = AsyncLoad(() => import('@/pages/center/address/detail'))
const CenterCollect = AsyncLoad(() => import('@/pages/center/collect'))
const CenterFocus = AsyncLoad(() => import('@/pages/center/focus'))
const CenterBail = AsyncLoad(() => import('@/pages/center/bail'))
const CenterCoupon = AsyncLoad(() => import('@/pages/center/coupon'))
const CenterCouponList = AsyncLoad(() => import('@/pages/center/coupon/list'))
const CenterProblem = AsyncLoad(() => import('@/pages/center/problem'))
const CenterProblemDetail = AsyncLoad(() => import('@/pages/center/problem/detail'))
const CenterWithdrawal = AsyncLoad(() => import('@/pages/center/withdrawal'))
const CenterWithdrawalResult = AsyncLoad(() => import('@/pages/center/withdrawal/result'))
// 取货时间
const CenterPickup = AsyncLoad(() => import('@/pages/center/pickup'))

// 购物车
const ShopCart = AsyncLoad(() => import('@/pages/shopCart'))

// 新人、vip 详情页
const ViperDetail = AsyncLoad(() => import('@/pages/viper/detail'))
const NewerDetail = AsyncLoad(() => import('@/pages/newer/detail'))

// 订单
const Order = AsyncLoad(() => import('@/pages/order'))
const OrderDetail = AsyncLoad(() => import('@/pages/order/detail'))
const OrderConfirm = AsyncLoad(() => import('@/pages/order/confirm'))
const OrderPayResult = AsyncLoad(() => import('@/pages/order/confirm/result'))
const ExchangeOrder = AsyncLoad(() => import('@/pages/order/exchange'))
const ExchangeOrderDetail = AsyncLoad(() => import('@/pages/order/exchange/detail'))
const ExchangeOrderResult = AsyncLoad(() => import('@/pages/order/exchange/result'))

// 积分商城
const IntegralMall = AsyncLoad(() => import('@/pages/integral/mall'))
const IntegralGoodsDetail = AsyncLoad(() => import('@/pages/integral/detail'))
const BuyIntro = AsyncLoad(() => import('@/pages/integral/buy-intro'))
const IntegralRecord = AsyncLoad(() => import('@/pages/integral/record'))

// 入驻
const EnterPage = AsyncLoad(() => import('@/pages/enter'))
const EnterApplyPage = AsyncLoad(() => import('@/pages/enter/apply'))
const EnterStatusPage = AsyncLoad(() => import('@/pages/enter/status'))
const StoreEntrance = AsyncLoad(() => import('@/pages/entrance'))

// 平台协议
const PlatformAgreement = AsyncLoad(() => import('@/pages/center/agreement/platform'))

// 限时
const LimitedTime = AsyncLoad(() => import('@/pages/limitedTime'))
const ApplySaleAfter = AsyncLoad(() => import('@/pages/saleAfter/apply'))
const SaleAfterList = AsyncLoad(() => import('@/pages/saleAfter'))
const SaleAfterDetail = AsyncLoad(() => import('@/pages/saleAfter/detail'))
const SaleAfterReturn = AsyncLoad(() => import('@/pages/saleAfter/return'))
const ReturnAgreement = AsyncLoad(() => import('@/pages/saleAfter/ReturnAgreement'))
const SaleAfterApplySubmit = AsyncLoad(() => import('@/pages/saleAfter/ApplySubmit'))

// 商品详情
const GoodsDetail = AsyncLoad(() => import('@/pages/goods/detail'))

// 需要登录状态才能进入的路由项，跳登录前会把当前地址保存，用于登录成功后的跳转
const RouteWithLogined = (props) => {
  const userId = getUserId();
  console.debug('RouteWithLogined.props', props, userId);
  if (!userId || userId === 1) {
    // 用户未登录
    localStorage.setItem('LOGIN_TO_PATH', props.path);
    return <Redirect to="/login" />
  }
  return <Route {...props} />
}

const Router = () => (
  <HashRouter>
    <Switch>
      {/* <Route path="/"  exact></Route> */}
      <Redirect path="/" to="/mall" exact></Redirect>
      <Route path="/mall" component={Home} exact></Route>
      <Route path="/guarantee" component={Guarantee} exact></Route>
      <Route path="/login" component={Login} exact></Route>
      <Route path="/login/auth" component={AuthIndex} exact></Route>
      <Route path="/user/agreement" component={UserAgreement} exact></Route>
      <Route path="/user/legalPrivacy" component={LegalPrivacy} exact></Route>
      <Route path="/search" component={Search} exact></Route>
      <Route path="/artist" component={Artist} exact></Route>
      <Route path="/artist/detail" component={ArtistDetail} exact></Route>
      <Route path="/hotShot/detail" component={HotShotDetail} exact></Route>
      <Route path="/auction" component={Auction} exact></Route>
      <Route path="/auction/detail" component={AuctionDetail} exact></Route>
      <Route path="/auction/search" component={AuctionSearch} exact></Route>
      <Route path="/auction/bid/result" component={AuctionBidResult} exact></Route>
      {/* 须知 */}
      <Route path="/auction/notice" component={AuctionNotice} exact></Route>
      <Route path="/oeuvre" component={Oeuvre} exact></Route>
      <Route path="/oeuvre/detail" component={OeuvreDetail} exact></Route>
      <Route path="/channel" component={Channel} exact></Route>
      {/* 推荐 */}
      <Route path="/recommend" component={Recommend} exact></Route>
      {/* 专场 */}
      <Route path="/special" component={Special} exact></Route>
      {/* 订单 */}
      {/* 订单物流 */}
      <RouteWithLogined path="/order/logistics" component={OrderLogistics} exact></RouteWithLogined>
      <RouteWithLogined path="/order/logisticsOrder" component={OrderLogisticsOrder} exact></RouteWithLogined>
      <Route path="/auth" component={Auth} exact></Route>
      {/* 购物车 */}
      <Route path="/shopCart" component={ShopCart} exact></Route>
      {/* 个人中心 */}
      <RouteWithLogined path="/center" component={Center} exact></RouteWithLogined>
      <Route path="/center/msg" component={CenterMessage} exact></Route>
      <Route path="/center/msg/detail" component={CenterMessageDetail} exact></Route>
      <RouteWithLogined path="/center/compete" component={CenterCompete} exact></RouteWithLogined>
      <Route path="/center/compete/detail" component={CompeteDetail} exact></Route>
      <Route path="/center/compete/pay" component={CompetePay} exact></Route>
      <Route path="/center/adviser" component={ArtisticAdviser} exact></Route>
      <Route path="/center/newer" component={NewExclusive} exact></Route>
      <Route path="/center/vip" component={VipExclusive} exact></Route>
      <Route path="/center/equity" component={VipEquity} exact></Route>
      <Route path="/center/equity/detail" component={VipEquityDetail} exact></Route>
      <Route path="/center/service" component={CenterService} exact></Route>
      <Route path="/center/address" component={CenterAddress} exact></Route>
      <Route path="/center/address/detail" component={CenterAddressDetail} exact></Route>
      <Route path="/center/collect" component={CenterCollect} exact></Route>
      <Route path="/center/focus" component={CenterFocus} exact></Route>
      <Route path="/center/bail" component={CenterBail} exact></Route>
      <Route path="/center/coupon" component={CenterCoupon} exact></Route>
      <Route path="/center/coupon/list" component={CenterCouponList} exact></Route>
      <Route path="/center/problem" component={CenterProblem} exact></Route>
      <Route path="/center/problem/detail" component={CenterProblemDetail} exact></Route>
      <Route path="/center/withdrawal" component={CenterWithdrawal} exact></Route>
      <Route path="/center/withdrawal/result" component={CenterWithdrawalResult} exact></Route>
      <Route path="/center/pickup" component={CenterPickup} exact></Route>
      <Route path="/center/info" component={CenterInfo} exact></Route>
      <Route path="/center/info/nickname" component={CenterInfoNickname} exact></Route>
      <Route path="/center/agreement/platform" component={PlatformAgreement} exact></Route>
      {/* 兑换订单 */}
      <RouteWithLogined path="/order" component={Order} exact></RouteWithLogined>
      <RouteWithLogined path="/order/detail" component={OrderDetail} exact></RouteWithLogined>
      <RouteWithLogined path="/order/confirm" component={OrderConfirm} exact></RouteWithLogined>
      <RouteWithLogined path="/order/pay/result" component={OrderPayResult} exact></RouteWithLogined>
      <RouteWithLogined path="/order/exchange" component={ExchangeOrder} exact></RouteWithLogined>
      <RouteWithLogined path="/order/exchange/detail" component={ExchangeOrderDetail} exact></RouteWithLogined>
      <RouteWithLogined path="/order/exchange/result" component={ExchangeOrderResult} exact></RouteWithLogined>
      <Route path="/integral/mall" component={IntegralMall} exact></Route>
      <Route path="/integral/buy" component={BuyIntro} exact></Route>
      <Route path="/integral/record" component={IntegralRecord} exact></Route>
      <Route path="/integral/detail" component={IntegralGoodsDetail} exact></Route>
      {/* 入驻 */}
      <Route path="/enter" component={EnterPage} exact></Route>
      <Route path="/enter/apply" component={EnterApplyPage} exact></Route>
      <Route path="/enter/status" component={EnterStatusPage} exact></Route>
      <Route path="/entrance" component={StoreEntrance} exact></Route>
      <Route path="/limited/time" component={LimitedTime} exact></Route>
      <Route path="/saleAfter/apply" component={ApplySaleAfter} exact></Route>
      <Route path="/saleAfter" component={SaleAfterList} exact></Route>
      <Route path="/saleAfter/detail" component={SaleAfterDetail} exact></Route>
      <Route path="/saleAfter/return" component={SaleAfterReturn} exact></Route>
      <Route path="/saleAfter/agreement" component={ReturnAgreement} exact></Route>
      <Route path="/saleAfter/applySubmit" component={SaleAfterApplySubmit} exact></Route>
      <Route path="/goods/detail" component={GoodsDetail} exact></Route>
      <Route path="/newer/detail" component={NewerDetail} exact></Route>
      <Route path="/viper/detail" component={ViperDetail} exact></Route>
    </Switch>
  </HashRouter>
)

export default Router
