import { Toast } from 'antd-mobile'
import html2canvas from 'html2canvas'
const getHost = () => {
  return 'https://wxpm.suodaojia.cn'
}
const getUserId = () => {
  let userId = localStorage.getItem('userId')
  if (!userId) {
    try {
      const wxUserInfo = JSON.parse(localStorage.getItem('wxUser'))
      userId = wxUserInfo.dealerId
    } catch (err) {
      userId = 1
    }
  }
  return userId
}

const verifyAuth = (history, type = 0) => {
  const userId = getUserId()
  console.log('-----userId--->', userId)
  if (parseInt(userId) === 1) {
    if (type === 0) {
      history.replace('/login')
    } else {
      history.push('/login')
    }
    return false
  }
  return true
}

const getOpenId = () => {
  try {
    const wxUserInfo = JSON.parse(localStorage.getItem('wxUser'))
    return wxUserInfo?.userH5Entity?.userId
  } catch (err) {
    return ''
  }
}

const getCenterInfo = () => {
  try {
    const centerInfo = JSON.parse(localStorage.getItem('center'))
    return centerInfo
  } catch (err) {
    return ''
  }
}

const setTitle = title => {
  document.title = title
  let i = document.createElement('iframe')
  i.style.display = 'none'
  i.onload = function () {
    setTimeout(function () {
      i.remove()
    }, 9)
  }
  document.body.appendChild(i)
}

const generatePoster = _dom => {
  return new Promise((resolve, reject) => {
    html2canvas(document.querySelector(_dom), { useCORS: true, allowTaint: false })
      .then(canvas => {
        const imgUrl = canvas.toDataURL('image/png') // 将canvas转换成img的src流
        resolve(imgUrl)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export { getHost, getUserId, getOpenId, verifyAuth, getCenterInfo, setTitle, generatePoster }
